
import React,  {Component} from 'react';
import {useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import queryString from 'query-string';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import CheckIcon from '@mui/icons-material/Check';
import '../App.css';
import { Nav,Navbar,Button,Form,Row,Modal,Col} from 'react-bootstrap';
import Loader from "react-js-loader";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ReactPaginate from 'react-paginate';
import { BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import Card from 'react-bootstrap/Card'
import { isExpired, decodeToken }from "react-jwt";
import zIndex from '@material-ui/core/styles/zIndex';
const key = "MIICXAIBAAKBgQCiQ5lLUPoLiI3VI69VfZ32tWjsL6HvqzYEtUbxutptHb3PYhKcbqirJ2cADUyWBWpfWgAQyshSciov5PhskWF5wRyhxc0WOLuK72icFqOu2ZLE2TvRvAzjNT2TaBHBeeJ2t39u6pPBz9ejZuXc05AapG2Jh7HfCORkTsCIhwGydwIDAQABAoGAT1wYzMeF/RJuQV85mWcG9w8NKs53y68yxDoQ0ZBNaKCztaGSFwR5UzhZZsn"
const CryptoJS = require("crypto-js");

var errocode = 0;

var listdata=[];


class Support extends Component{
    
    constructor(props) {
    super(props);
      this.state = {
        userid:0,
        listsupport: [],
        infosupport:[],
        modaladd: false,
        modalupdate: false,
        modaldelete: false,
        idticket:0,
        objetsupport:'',
        loadershow:false,
        tokenexist:'',
        tokenvalue : '',
        wbslink:'',
        listsupportinit: [],
        filterdata:'',
        offset: 0,
        listsupportpage: [],
        perPage: 5,
        currentPage: 0,
        loadingdata: true,
        idtypeuser:0,
        selectedFile: [],
        imagefilename : '',
        imageselect: '',
        newmessage:'',
        statussupportinfo:0,
        modalresolved:false,
        isModalloading:false,
      };

      this.handlePageClick = this.handlePageClick.bind(this);

  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
        currentPage: selectedPage,
        offset: offset
    }, () => {
        this.loadMoreData(this.state.listsupportpage)
    });

};

  loadMoreData(data) {

  const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
  this.setState({
    pageCount: Math.ceil(data.length / this.state.perPage),
    listsupport:slice
  })

  window.scrollTo(0, 0)

  }

  filterlist(e){

  
    this.setState({filterdata: e})
  
    if(e.lenght == 0){
      this.setState({listsupport: this.state.listsupportinit})
      return;
    }
  
    const filteredItems = this.state.listsupportinit.filter((supportlist) => {
      return (supportlist.objectsupportinfo).toLowerCase().includes(e.toLowerCase());
    });
  
    
    if(filteredItems.length>0){
  
      this.setState({listsupport: filteredItems})
  
      var slice = filteredItems.slice(this.state.offset, this.state.offset + this.state.perPage)
            
            this.setState({
                pageCount: Math.ceil(filteredItems.length / this.state.perPage),
                listsupport:slice,
                listsupportpage:filteredItems,
            })
  
    }else{
  
      this.setState({listsupport: [], listsupportpage:[]})
  
    }
   
    
  }


  componentWillMount() {

    let wbslinkload = sessionStorage.getItem('wbs');

    this.setState({ wbslink:wbslinkload})

    let lnguser = sessionStorage.getItem('langueuser');

    this.setState({ lng: lnguser})

    let tokenuser = sessionStorage.getItem('tokenunsaid');
  
    if(tokenuser === null || tokenuser === 'Undefined'){

      this.setState({ tokenexist: false})
      return

    }else{

      this.setState({ tokenvalue: tokenuser})
      this.reloaddata();
      
    }

    const myDecodedToken = decodeToken(tokenuser);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(tokenuser);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    const datauser = JSON.stringify(myDecodedToken.init).replace(/\"/g, "");
        
    var bytes  = CryptoJS.AES.decrypt(datauser.toString().replace(/p1L2u3S/g, '+' ).replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=').toString(), key);
    var originaldata = bytes.toString(CryptoJS.enc.Utf8);
    var userdata  = originaldata.split('-');

    this.setState({
      idtypeuser: parseInt(userdata[1]),
      userid: parseInt(userdata[2]),
    })

    let tokencredit = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");
    
    fetch(wbslinkload + `getdatauser`,{                  
          
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ tokencredit},

      })

      .then(res => {

        errocode = res.status;
  
        if (!res.ok) {
            
            return res.json().then(json => { throw json; });
  
        }
  
        return res.json();
  
      })
      .then(data => {

        if(JSON.stringify(data.status).replace(/"/g, "") == "unsubscribed" && window.location.pathname != "/Buycredit"){

          document.location.href = "/Buycredit";

        }else{

          this.setState({
            loadingdata:false
          })
          
        }
        
    })

  }

  reloaddata () {

    if(this.state.tokenvalue !== ''){

          this.setState({ reload: true})
          
    }

  }
    
  async componentDidMount() { 

    let token = sessionStorage.getItem('tokenunsaid');
    
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

    const response = await fetch(this.state.wbslink + 'listticket',{                       
      mode:'cors',
      method:'post',
      headers: {'accept': 'application/json','Authorization': 'Bearer '+ token},
      });
    const data = await response.json();

    if(JSON.stringify(data).replace(/\"/g, "") !== '0'){

        this.setState({ listsupport: data, listsupportinit: data,listsupportpage:data,loadershow: false})

        data.forEach((item)=>{
          listdata.push({ idticket :item.idticket ,	idusersupportinfo:item.idusersupportinfo,	datesupportinfo:item.datesupportinfo,	objectsupportinfo	:item.objectsupportinfo,statussupportinfo	:item.statussupportinfo	});
        })
      
        var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
        
        this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            listsupportinit :data,
            listsupport:slice
        })
    
    }

    document.title = "Ticket";

    return;

    
    
  }


loaderdesactive(){

  this.setState({
    loadershow: false,
    selectedFile:'',
    idticket: 0,
    objetsupport: '',
    textloading:''
  });
  
}

onFileChange = event => { 
   
  this.setState({
    selectedFile: event.target.files[0],
    imagefilename : event.target.files[0].name,
    imageselect: URL.createObjectURL(event.target.files[0]),
   }); 

}; 


infoticket(idticket,objetsupport,statussupportinfo){

  let token = sessionStorage.getItem('tokenunsaid');
    
    const myDecodedToken = decodeToken(token);

    if(myDecodedToken == null){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }
    const isMyTokenExpired = isExpired(token);

    if(isMyTokenExpired == true){
      this.setState({ typeuser: null})
      document.location.href = '/login';
      return;
    }

    token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

    this.setState({
      idticket: idticket,
      statussupportinfo: statussupportinfo,
    })


    // Create an object of formData 
    const formData = new FormData(); 

    formData.append("idticket",idticket);

    if(this.state.lng === "FR"){
      this.setState({

        textloading:'Chargement des données ...',
      
      });
    }else{
      this.setState({

        textloading:'Loading data ...',
      
      });
    }

    this.setState({
      loadershow: true,
      isModalloading: true
    });

    fetch(this.state.wbslink + `infoticket`,{     
  
      mode: 'cors',     
      method:'post',
      headers: {'Authorization': 'Bearer '+ token},
      body: formData

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })

    .then(data => {

      if(JSON.stringify(data).replace(/\"/g, "") !== '0'){

        this.setState({
          infosupport: data,
          objetsupport: objetsupport,
          newmessage:'',
          selectedFile: [],
          imagefilename : '',
          imageselect: '',
          isModalloading: false,
          loadershow:false,
          modalupdate: true,
        })

        this.delayedGreeting("lastlineadd",data)    

        //let lastElement = data[data.length - 1];

        //this.delayedGreeting(lastElement.iddatasupportinfo)        

            
    
    }

    })

    .catch(err => {
    
      if(errocode == 401 || errocode == 0){
        
        sessionStorage.removeItem("tokenunsaid");
        document.location.href = "/login";
  
        return
  
      }
      
      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))
  
      this.setState({
        loadershow: false
      })
  
    });  

}

sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

async delayedGreeting(iddatasupportinfo,data) {
  this.setState({
    infosupport: data
  })
  await this.sleep(1000)
  this.scrolllastline(iddatasupportinfo)     
}


scrolllastline(idlastline){

  var element = document.getElementById(idlastline);
    
  if(element !== 'null' ){
    element.scrollIntoView({ block: 'end',  behavior: 'smooth' });
  }

  element = 'null';

}

addsupport(){


  if(this.state.objetsupport === '' && this.state.lng === 'FR'){

    this.setState({ errorform: true, errormessage: 'Veuillez ajouter l\'objet.' });
    return
  
  }

  if(this.state.objetsupport === '' && this.state.lng === 'EN'){

    this.setState({ errorform: true, errormessage: 'Please add object.' });
    return
  
  }

  if(this.state.messagesupport === '' && this.state.lng === 'FR'){

    this.setState({ errorform: true, errormessage: 'Veuillez ajouter un message' });
    return
  
  }

  if(this.state.messagesupport === '' && this.state.lng === 'EN'){

    this.setState({ errorform: true, errormessage: 'Please add message.' });
    return
  
  }

  let token = sessionStorage.getItem('tokenunsaid');
    
  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired == true){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

  token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");


  // Create an object of formData 
  const formData = new FormData(); 

  formData.append("objectsupportinfo",this.state.objetsupport);
  formData.append("messagedatasupportinfo",this.state.messagesupport);

  if(this.state.imagefilename != ''){

    if(this.state.selectedFile.size > 50 * 1024 * 1024)
      {
        this.setState({
          loadershow: false,
          textloading:'Image need to be under 50Mb.'
        });
        
        return;
  
      }

    formData.append("imagedatasupportinfo",this.state.selectedFile);

  } 

  this.setState({
    loadershow: true,
  });


  if(this.state.lng === 'FR'){

    this.setState({ textloading: 'Création du ticket' });
  
  }else{

    this.setState({ textloading: 'Creating the ticket' });

  }

  fetch(this.state.wbslink + `addticket`,{                  
        
    mode: 'cors',     
    method:'post',
    headers: {'Authorization': 'Bearer '+ token},
    body: formData

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

    if(JSON.stringify(data).replace(/\"/g, "") === '0'){

      this.setState({ errorform: true, errormessage: 'Error add ticket.' });

    }else{

      this.setState({
        idticket: 0,
        objetsupport: '',
        messagesupport: '',
        selectedFile: [],
        imagefilename : '',
        imageselect: '',
        modaladd: false,
        textloading:'',
        newmessage:'',
        loadershow:false
      })

      listdata.length= 0;

      this.setState({ listsupport: data, listsupportinit: data,listsupportpage:data,loadershow: false})

      data.forEach((item)=>{
        listdata.push({ idticket :item.idticket ,	idusersupportinfo:item.idusersupportinfo,	datesupportinfo:item.datesupportinfo,	objectsupportinfo	:item.objectsupportinfo,statussupportinfo	:item.statussupportinfo	});
      })
    
      var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
      
      this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          listsupportinit :data,
          listsupport:slice
      })

      this.setState({
        idticket: 0,
        objetsupport: '',
        errorform: false,
        errormessage: '',
      })
    }

    })

    .catch(err => {

      if(errocode == 401 || errocode == 0){
        
        sessionStorage.removeItem("tokenunsaid");
        document.location.href = "/login";

        return

      }

      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

      this.setState({
        loadershow: false
      })

    });  

}


showaddticket(){

  this.setState({
    idticket: 0,
    objetsupport: '',
    messagesupport: '',
    modaladd: true
  })

}

showupdatesupport(idticket,textupadte,statussupportinfo){

  this.setState({
    idticket: idticket,
    objetsupport: textupadte,
    statussupportinfo:statussupportinfo,
    modalupdate: true
  })

}

updatesupport(){


  if(this.state.newmessage === '' && this.state.imagefilename === '' && this.state.lng === 'FR'){

    alert('Veuillez ajouter un message ou une image.' );
    this.setState({ errorform: true, errormessage: 'Veuillez ajouter un message ou une image.' });
    return
  
  }

  if(this.state.newmessage === '' && this.state.imagefilename === '' && this.state.lng === 'EN'){

    alert('Please add a message or image.');
    this.setState({ errorform: true, errormessage: 'Please add a message or image.' });
    return
  
  }

  let token = sessionStorage.getItem('tokenunsaid');
    
  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired == true){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

  token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

   // Create an object of formData 
   const formData = new FormData(); 

   formData.append("idticket",this.state.idticket);

   if(this.state.newmessage.length>0){

    formData.append("messagedatasupportinfo",this.state.newmessage);

   }
 
   if(this.state.imagefilename != ''){
 
     if(this.state.selectedFile.size > 50 * 1024 * 1024)
       {
         this.setState({
           loadershow: false,
           textloading:'Image need to be under 50Mb.'
         });
         
         return;
   
       }
 
     formData.append("imagedatasupportinfo",this.state.selectedFile);
 
   } 
 
   if(this.state.lng === 'FR'){
 
     this.setState({ textloading: 'Mettre à jour le ticket' });
   
   }else{
 
     this.setState({ textloading: 'Update ticket' });
 
   }

   this.setState({
    loadershow: true,
  });

   fetch(this.state.wbslink + `updateticket`,{                  
        
    mode: 'cors',     
    method:'post',
    headers: {'Authorization': 'Bearer '+ token},
    body: formData

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })

    .then(data => {

      if(data.length>0){

        this.setState({
          newmessage:'',
          selectedFile: [],
          imagefilename : '',
          imageselect: '',
          textloading:'',
          loadershow:false
        })

        this.delayedGreeting("lastlineadd",data)    

        //let lastElement = data[data.length - 1];

        //this.delayedGreeting(lastElement.iddatasupportinfo)        

    }

    })

    .catch(err => {
    
      if(errocode == 401 || errocode == 0){
        
        sessionStorage.removeItem("tokenunsaid");
        document.location.href = "/login";
  
        return
  
      }
      
      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))
  
      this.setState({
        loadershow: false
      })
  
    });  


}


showresolvedticket(idticket, objetsupport){

  this.setState({
    idticket: idticket,
    objetsupport: objetsupport,
    modalresolved: true
  })

}

resolvedticket(){

  let token = sessionStorage.getItem('tokenunsaid');
    
  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired == true){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

  token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");

   // Create an object of formData 
   const formData = new FormData(); 

   formData.append("idticket",this.state.idticket);

   fetch(this.state.wbslink + `resolvedticket`,{                  
        
    mode: 'cors',     
    method:'post',
    headers: {'Authorization': 'Bearer '+ token},
    body: formData

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })

    .then(data => {

      this.setState({
        idticket: 0,
        objetsupport: '',
        messagesupport: '',
        selectedFile: [],
        imagefilename : '',
        imageselect: '',
        modaladd: false,
        textloading:'',
        newmessage:'',
        loadershow:false,
        modalresolved:false
      })

      listdata.length= 0;

      this.setState({ listsupport: data, listsupportinit: data,listsupportpage:data,loadershow: false})

      data.forEach((item)=>{
        listdata.push({ idticket :item.idticket ,	idusersupportinfo:item.idusersupportinfo,	datesupportinfo:item.datesupportinfo,	objectsupportinfo	:item.objectsupportinfo,statussupportinfo	:item.statussupportinfo	});
      })
    
      var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
      
      this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          listsupportinit :data,
          listsupport:slice
      })

      this.setState({
        idticket: 0,
        objetsupport: '',
        errorform: false,
        errormessage: '',
      })

    })

    .catch(err => {
    
      if(errocode == 401 || errocode == 0){
        
        sessionStorage.removeItem("tokenunsaid");
        document.location.href = "/login";
  
        return
  
      }
      
      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))
  
      this.setState({
        loadershow: false
      })
  
    });  


}

showdeletesupport(idticket,text){
  
  this.setState({
    idticket: idticket,
    objetsupport: text,
    modaldelete: true
  })

}

deletesupport(){

  let token = sessionStorage.getItem('tokenunsaid');
    
  const myDecodedToken = decodeToken(token);

  if(myDecodedToken == null){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired == true){
    this.setState({ typeuser: null})
    document.location.href = '/login';
    return;
  }

  token = JSON.stringify(myDecodedToken.uid).replace(/\"/g, "");


  // Create an object of formData 
  const formData = new FormData(); 

  formData.append('_method', 'delete');

  fetch(this.state.wbslink + `support/`+this.state.idticket,{                  
        
    mode: 'cors',     
    method:'post',
    headers: {'Authorization': 'Bearer '+ token},
    body: formData

    })

    .then(res => {

      errocode = res.status;

      if (!res.ok) {
          
          return res.json().then(json => { throw json; });

      }

      return res.json();

    })
    .then(data => {

    if(data.length == 0){

      this.setState({ errorform: true, errormessage: 'Error add word.' });

    }else{

      listdata.length= 0;

      this.setState({ listsupport: data, listsupportinit: data,listsupportpage:data,loadershow: false})

      data.forEach((item)=>{
        listdata.push({ idticket :item.idticket ,supportword:item.supportword});
      })
    
      var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
      
      this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          listsupportinit :data,
          listsupport:slice
      })

      this.setState({
        idticket: 0,
        objetsupport: '',
        errorform: false,
        errormessage: '',
        modaldelete:false,
      })
    }

    })

    .catch(err => {

      if(errocode == 401 || errocode == 0){
        
        sessionStorage.removeItem("tokenunsaid");
        document.location.href = "/login";

        return

      }

      alert("Erreur :" + errocode + ", "+JSON.stringify(err.message).replace(/\"/g, ""))

      this.setState({
        loadershow: false
      })

    });  

}
    
  render() {

          return (

                <div>

                  {this.state.loadingdata == true?

                    null
                  
                    :

                    <div className="App" style={{marginTop:'80px'}}>
                  
                    {this.state.tokenvalue === '' ?
                              
  
                              <Redirect push to="/Login"/>
                                
  
                              :
  
                              null
  
                      }

                      {this.state.isModalloading === true ?

                      <Modal

                      show={this.state.isModalloading}

                      dialogClassName="modal-dialog"
                      aria-labelledby="example-custom-modal-styling-title" centered
                      >
                        <Modal.Body>
                                          
                            <div className="d-flex flex-column align-items-center" style={{margin:"20px"}}>

                                {this.state.loadershow === true ?
                              

                                  <Loader type="spinner-cub" bgColor={'#2A83E8'} title={this.state.textloading} color={'#2A83E8'} size={100} />
                                    

                                  :

                                  null

                                }
                        
                                
                            </div>

                      </Modal.Body>
                      </Modal>

                      :

                      null

                      }

                      {this.state.modaladd === true ?
                        
                        <Modal

                        show={this.state.modaladd}
                        onHide={ () => this.setState({modaladd:false,idticket: 0, objetsupport: '', messagesupport: '', selectedFile: [], imagefilename : '', imageselect: ''})}
                        dialogClassName="modal-dialog"
                        aria-labelledby="example-custom-modal-styling-title" centered
                        >
                          <Modal.Body>
                                      
                          <div className="d-flex flex-row justify-content-center">
                                            
                              <div className="d-flex flex-column" style={{margin:"20px"}}>
                                  
                                  <div className="d-flex flex-column">

                                      <div className="form-group" style={{margin:'5px'}}>
                                        <label>{this.state.lng ==="FR" ? 'Objet' : 'Object'}</label>
                                        <input type="email" className="form-control" value={this.state.objetsupport} onChange={(e) => this.setState({ objetsupport: e.target.value})} />
                                      </div>

                                      <div className="form-group" style={{margin:'5px'}}>
                                        <label>{this.state.lng ==="FR" ? 'Message' : 'Message'}</label>
                                        <textarea rows={7} type="text" className="form-control" value={this.state.messagesupport} onChange={(e) => this.setState({ messagesupport: e.target.value})} />
                                      </div>

                                       {this.state.imageselect === '' ?
                                      
                                        <div style={{textAlign:"center", marginTop:"20px"}}>
                                            {this.state.lng == 'FR'? 'Pas d\'image sélectionné':'No selected file'}                   
                                        </div>
        
                                        :
        
                                        <div className="App">
                                          <img alt={this.state.imagefilename} src={this.state.imageselect} style={{width:"90%", height:"200px", marginLeft:"5px"}}/> 
                                          <p>{this.state.imagefilename}</p>
                                        </div>
        
                                        }
        
                                        <div className='App'> 
                                          <label htmlFor="filePickerimgcat" style={{background:"#00934c", padding:"5px 10px", marginTop:'20px', color:'white', borderRadius:'5px',textAlign:'center', width:'200px' }} variant="primary" >{this.state.lng == 'FR'? 'Sélectionner une image':'Select an image'}</label>
                                          <input id="filePickerimgcat" style={{visibility:"hidden"}} name="imgcat" type="file" accept="image/*" onChange={this.onFileChange}/>
                                        </div>
        
        
                                        {this.state.loadershow === true ?
        
        
                                        <Loader type="spinner-cub" bgColor={'#0275d8'} title={this.state.textloading} color={'#0275d8'} size={100} />
                                          
        
                                        :
        
                                        null
        
                                        }
                                    
                                      <form>

                                      <div className='App'>
                                                  
                                            <Button className="btn"style={{ margin:"20px", width:'100px'}} onClick={ () => this.setState({modaladd:false,idticket: 0, objetsupport: '', messagesupport: '', selectedFile: [], imagefilename : '', imageselect: ''})} variant="danger">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                                            <Button className="btn" style={{ margin:"10px",right: 0, width:'100px'}} onClick={() => this.addsupport()} variant="primary">{this.state.lng ==="FR" ? 'Créer' : 'Create'}</Button>

                                      </div>
                                      
                                    </form>
                                  </div>
                                  
                              </div>

                          </div>    

                        </Modal.Body>
                        </Modal>

                        :

                        null

                        }

                         {/** 
                          <div className='d-flex justify-content-center'>
                            <label>{this.state.lng ==="FR" ? 'Mot' : 'Word'}</label>
                          </div>
  
                          <div className='d-flex justify-content-center'>
                            <input style={{width:'300px'}} type="text" className="form-control" value={this.state.objetsupport} onChange={(e) => this.setState({ objetsupport: e.target.value})} />                    
                          </div>
  
                          <div className='d-flex justify-content-center'>
  
                              {this.state.errorform == true ? 
  
                              <Alert severity="error">
                                <AlertTitle>{this.state.lng ==="FR" ? 'Erreur :' : 'Error'}</AlertTitle>
                                {this.state.errormessage}
                              </Alert>
  
                              :
  
                              null
  
                              }
  
                          </div>
  
                        <div className='d-flex justify-content-center'>
       
                          <button style={{margin:'20px'}}onClick={() => this.addsupport()} className="btn btn-success">{this.state.lng ==="FR" ? 'AJOUTER' : 'ADD'}</button> 
                                                               
                        </div> 
                        */}
  
                    
                    {this.state.modalupdate === true ?
  
                    <Modal
                    
                    show={this.state.modalupdate}
                    onHide={ () => this.setState({modalupdate:false})}
                    dialogClassName="modal-dialog"
                    aria-labelledby="example-custom-modal-styling-title" centered
                    >
                      <Modal.Body>
                        <div className="d-flex flex-column modaldetailsticket">
  
                            <p style={{textAlign:"center", fontWeight:"bold", fontSize:"20px", color:"#2A83E8"}}>{this.state.objetsupport}</p>

                            {this.state.infosupport.map((row) => (

                              <div key={parseInt(row.iddatasupportinfo)} id={parseInt(row.iddatasupportinfo)} style={{marginTop:"10px"}}>

                                <p style={{color:"black", marginBottom:"-10px", marginRight:parseInt(row.iddevtech) === parseInt(this.state.userid)? "5%":"0%", textAlign:parseInt(row.iddevtech) === parseInt(this.state.userid)?"right":"left"}}>{parseInt(new Date(Date.parse(row.datesupport.replace(/[-]/g, '/'))).getDate()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.datesupport.replace(/[-]/g, '/'))).getDate())}</label>:parseInt(new Date(Date.parse(row.datesupport.replace(/[-]/g, '/'))).getDate())}/{parseInt(new Date(Date.parse(row.datesupport.replace(/[-]/g, '/'))).getMonth())+1 < 10 ? <label>0{parseInt(new Date(Date.parse(row.datesupport.replace(/[-]/g, '/'))).getMonth())+1}</label>:parseInt(new Date(Date.parse(row.datesupport.replace(/[-]/g, '/'))).getMonth())+1}/{new Date(Date.parse(row.datesupport.replace(/[-]/g, '/'))).getFullYear()} - {parseInt(new Date(Date.parse(row.datesupport.replace(/[-]/g, '/'))).getHours()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.datesupport.replace(/[-]/g, '/'))).getHours())}</label>:parseInt(new Date(Date.parse(row.datesupport.replace(/[-]/g, '/'))).getHours())}:{parseInt(new Date(Date.parse(row.datesupport.replace(/[-]/g, '/'))).getMinutes()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.datesupport.replace(/[-]/g, '/'))).getMinutes())}</label>:parseInt(new Date(Date.parse(row.datesupport.replace(/[-]/g, '/'))).getMinutes())}:{parseInt(new Date(Date.parse(row.datesupport.replace(/[-]/g, '/'))).getSeconds()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.datesupport.replace(/[-]/g, '/'))).getSeconds())}</label>:parseInt(new Date(Date.parse(row.datesupport.replace(/[-]/g, '/'))).getSeconds())}s <span>{parseInt(row.iddevtech) === parseInt(this.state.userid)?"":<span>{this.state.lng ==="FR" ? ' par l\'agent UNSAID' : ' by UNSAID agent'}</span>}</span></p>

                                  {row.messagedatasupportinfo.length>0?
                                      
                                      <Card style={{ width: '80%', margin:"2%", marginLeft:parseInt(row.iddevtech) === parseInt(this.state.userid)? "15%":"0%", borderRadius:"5px"}}>

                                        <Card.Body  key={row.iddatasupportinfo} style={{backgroundColor: parseInt(row.iddevtech) === parseInt(this.state.userid)? "#2A83E8":"#ebebeb", borderRadius:"5px"}}>
                                          
                                          <Card.Text>

                                            <p style={{color:parseInt(row.iddevtech) === parseInt(this.state.userid)? "white":"black", marginTop:"-10px"}}>{row.messagedatasupportinfo}</p>

                                  

                                        </Card.Text>
                                        
                                      </Card.Body>

                                    </Card>

                                    :

                                    null

                                  }

                                {row.imagedatasupportinfo.length>0?
                                      
                                      <div className='App' style={{ width: '80%', margin:"2%", marginLeft:parseInt(row.iddevtech) === parseInt(this.state.userid)? "15%":"0%", borderRadius:"5px"}}>
                                        <img src={this.state.wbslink + 'images/support/' + row.imagedatasupportinfo}  style={{marginTop:"0px",width:"90%", height:"150px",marginLeft:"5px", borderRadius:"5px"}}/>
                                      </div>

                                      :

                                      null

                                  }

                              </div>

                            ))}

                            {parseInt(this.state.statussupportinfo) === 0 ?

                              <div>

                                <div className="form-group" style={{margin:'5px'}}>
                                  <label>{this.state.lng ==="FR" ? 'Message' : 'Message'}</label>
                                  <textarea rows={5} type="text" className="form-control" value={this.state.newmessage} onChange={(e) => this.setState({ newmessage: e.target.value})} />
                                </div>

                                  {this.state.imageselect === '' ?
                                
                                  <div style={{textAlign:"center", marginTop:"20px"}}>
                                      {this.state.lng == 'FR'? 'Pas d\'image sélectionnée':'No selected file'}                   
                                  </div>

                                  :

                                  <div className="App">
                                    <img alt={this.state.imagefilename} src={this.state.imageselect} style={{width:"90%", height:"200px", marginLeft:"5px"}}/> 
                                    <p>{this.state.imagefilename}</p>
                                  </div>

                                  }

                                  <div className='App'> 
                                    <label htmlFor="filePickerimgcat" style={{background:"#2A83E8", padding:"5px 10px", marginTop:'20px', color:'white', borderRadius:'5px',textAlign:'center', width:'200px' }} variant="primary" >{this.state.lng == 'FR'? 'Sélectionner une image':'Select an image'}</label>
                                    <input id="filePickerimgcat" style={{visibility:"hidden"}} name="imgcat" type="file" accept="image/*" onChange={this.onFileChange}/>
                                  </div>


                                  {this.state.loadershow === true ?


                                  <Loader type="spinner-cub" bgColor={'#0275d8'} title={this.state.textloading} color={'#0275d8'} size={100} />
                                    

                                  :

                                  null

                                  }
                                      
                                <div className='d-flex flex-row justify-content-center' >
                                            
                                      <Button className="btn"style={{ margin:"10px", width:'150px'}} onClick={ () => this.setState({modalupdate:false,idsupport: 0,infosupport:[],objetsupport:'', newmessage:''})} variant="danger">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                                      <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.updatesupport()} variant="success">{this.state.lng ==="FR" ? 'Modifier' : 'Update'}</Button>
      
                                </div>

                              </div>

                              :

                              <div className='d-flex flex-row justify-content-center' >
                                            
                                      <Button className="btn"style={{ margin:"10px", width:'150px'}} onClick={ () => this.setState({modalupdate:false,idsupport: 0,infosupport:[],objetsupport:'', newmessage:''})} variant="success">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
 
                              </div>

                            }

                          <div style={{height:"20px", marginBottom:"30px"}} id="lastlineadd">

                          </div>  

                        </div>          
                      
                    </Modal.Body>
                    </Modal>
  
                    :
  
                    null
  
                    }
  
                    {this.state.modaldelete === true ?
  
                    <Modal
  
                    show={this.state.modaldelete}
                    onHide={ () => this.setState({modaldelete:false})}
                    dialogClassName="modal-dialog"
                    aria-labelledby="example-custom-modal-styling-title" centered
                    >
                      <Modal.Body>
                                  
                      <div className="d-flex flex-row justify-content-center">
                                        
                          <div className="d-flex flex-column" style={{margin:"20px"}}>
                              
                              <div className="d-flex flex-column">
                                  <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.lng ==="FR" ? 'Voulez-vous supprimer le mot' : 'Would you like to delete'}</p>
                                  <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.objetsupport} &nbsp;</p>
                                 
                                  <form>
  
                                  <div className='row text-white d-flex align-items-center'>
                                              
                                        <Button className="btn"style={{ margin:"20px", width:'150px'}} onClick={ () => this.setState({modaldelete:false})} variant="primary">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                                        <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.deletesupport()} variant="danger">{this.state.lng ==="FR" ? 'Supprimer' : 'Delete'}</Button>
  
                                  </div>
                                  
                                </form>
                              </div>
                              
                          </div>
  
                      </div>    
  
                    </Modal.Body>
                    </Modal>
  
                    :
  
                    null
  
                    }

                    {this.state.modalresolved === true ?
                      
                      <Modal

                      show={this.state.modalresolved}
                      onHide={ () => this.setState({modalresolved:false})}
                      dialogClassName="modal-dialog"
                      aria-labelledby="example-custom-modal-styling-title" centered
                      >
                        <Modal.Body>
                                    
                        <div className="d-flex flex-row justify-content-center">
                                          
                            <div className="d-flex flex-column" style={{margin:"20px"}}>
                                
                                <div className="d-flex flex-column">
                                    <p style={{fontWeight:"bold", textAlign:"center", marginTop:"20px"}}>{this.state.objetsupport} &nbsp;</p>
                                  
                                    <form>

                                    <div className='d-flex flex-row align-items-center'>
                                                
                                          <Button className="btn"style={{ margin:"20px", width:'150px'}} onClick={ () => this.setState({modalresolved:false})} variant="danger">{this.state.lng ==="FR" ? 'Fermer' : 'Close'}</Button>
                                          <Button className="btn" style={{ margin:"10px",right: 0, width:'150px'}} onClick={() => this.resolvedticket()} variant="primary">{this.state.lng ==="FR" ? 'Résolu' : 'Resolved'}</Button>

                                    </div>
                                    
                                  </form>
                                </div>
                                
                            </div>

                        </div>    

                      </Modal.Body>
                      </Modal>

                      :

                      null

                      }

                    {isBrowser?

                        <div className='d-flex justify-content-center'>
                              
                          <button style={{margin:'20px'}}onClick={() => this.showaddticket()} className="btn btn-success">{this.state.lng ==="FR" ? 'Créer un ticket' : 'Create ticket'}</button> 
                                                              
                        </div> 

                        :

                        <div className='paginationmobile' style={{ backgroundColor:this.state.listsupport.length>0?"#162938":"transparent", bottom:20}} >

                        {this.state.listsupport.length>0?
                          
                          <ReactPaginate
                          previousLabel={"<<"}
                          previousClassName={"prevClass"}
                          previousLinkClassName={"prevClass"}
                          nextLabel={">>"}
                          nextClassName={"next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.pageCount}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          onPageChange={this.handlePageClick}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}/>

                          :

                          null
                          
                        }
                  
                        <Button   className="btn" style={{width:'50px',height:"50px", borderRadius:"25px",zIndex:10000}} onClick={() => this.showaddticket()} variant="success"><span><AddIcon /></span></Button>
                      
                      </div>

                    }
  
                    <div className='centrervue' >
                    
                    {this.state.listsupport.length>0?

                      <input className="inputfind" style={{marginTop: isBrowser?"0px":"20px"}} type="text" name="search" placeholder={this.state.lng ==='FR'? 'Rechercher': 'Search'} value={this.state.filterdata} onChange={(e) => this.filterlist(e.target.value)}/>
                      
                      :

                      null

                    }

                    <BrowserView>

                    {this.state.listsupport.length>0?

                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            
                            <TableCell >{this.state.lng ==="FR" ? 'Date' : 'Date'}</TableCell>
                            <TableCell >{this.state.lng ==="FR" ? 'Utilisateur' : 'User'}</TableCell>
                            <TableCell >{this.state.lng ==="FR" ? 'Objet' : 'Object'}</TableCell>
                            <TableCell style={{textAlign:"center"}}>{this.state.lng ==="FR" ? 'Modifier' : 'Update'}</TableCell>
                            <TableCell style={{textAlign:"center"}}>{this.state.lng ==="FR" ? 'Résolu' : 'Resolved'}</TableCell>
                            {/*<TableCell style={{textAlign:"center"}}>{this.state.lng ==="FR" ? 'Supprimer' : 'Delete'}</TableCell>*/}
                            
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.listsupport.map((row) => (
                            <TableRow key={row.idsupportinfo}>
  
                  
                              <TableCell >{parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getDate()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getDate())}</label>:parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getDate())}/{parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getMonth())+1 < 10 ? <label>0{parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getMonth())+1}</label>:parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getMonth())+1}/{new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getFullYear()} - {parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getHours()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getHours())}</label>:parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getHours())}:{parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getMinutes()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getMinutes())}</label>:parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getMinutes())}:{parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getSeconds()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getSeconds())}</label>:parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getSeconds())}s</TableCell>
                              <TableCell >{row.name}</TableCell>
                              <TableCell >{row.objectsupportinfo}</TableCell>
                              <TableCell style={{textAlign:"center"}}><span onClick={() => this.infoticket(row.idsupportinfo,row.objectsupportinfo,row.statussupportinfo)} style={{color:'#2A83E8'}}><EditIcon /></span></TableCell>
                              {parseInt(row.statussupportinfo)== 1 ?

                                <TableCell style={{textAlign:"center"}}><span style={{color:'#198754'}}><CheckIcon /></span></TableCell>

                                :

                                <TableCell ><span onClick={() => this.showresolvedticket(row.idsupportinfo,row.objectsupportinfo)}><div className='loadertranscription' style={{marginTop:"5px",marginLeft:"40%"}}></div></span></TableCell>
                            
                              }
                              
                              {/*<TableCell style={{textAlign:"center"}}><span onClick={() => this.showdeletesupport(row.idsupportinfo,row.objectsupportinfo)} style={{color:'#dc3545'}}><DeleteIcon /></span></TableCell>*/}
  
  
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    :

                    null

                    }
  
                        {this.state.listsupport.length>0?
  
                        <ReactPaginate
                        previousLabel={"<< prev"}
                        previousClassName={"prevClass"}
                        previousLinkClassName={"prevClass"}
                        nextLabel={"next >>"}
                        nextClassName={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={3}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}/>
  
                        :
  
                        null
                        }
  
                    </BrowserView>
  
                    </div> 
  
                    <div>

                    <MobileView>
  
                      <div >

                      {this.state.listsupport.map((row) => (

                          <Card style={{ width: '90%', margin:"5%",fontSize:"14px",textAlign:"left", marginTop:"-10px", borderWidth:"2px", borderColor:"#ebebeb",backgroundColor: parseInt(row.active) === 0 ? "#F5B041": "#f5fffa"}}>
                          
                          <Card.Body  key={row.email} >

                          <div className="d-flex flex-row">
                            <div style={{width:"95%"}}><p style={{color:"black", margin:"-10px"}}>
                              <p style={{fontWeight:"bold"}}>{row.objectsupportinfo}</p>
                              <p >{row.nomutilisateur} {row.prenomsutilisateur}</p>
                             
                              {this.state.lng=== "FR"? "Crée le ":"Created at "}<span >{parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getDate()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getDate())}</label>:parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getDate())}/{parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getMonth())+1 < 10 ? <label>0{parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getMonth())+1}</label>:parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getMonth())+1}/{new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getFullYear()} - {parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getHours()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getHours())}</label>:parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getHours())}:{parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getMinutes()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getMinutes())}</label>:parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getMinutes())}:{parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getSeconds()) < 10 ? <label>0{parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getSeconds())}</label>:parseInt(new Date(Date.parse(row.datesupportinfo.replace(/[-]/g, '/'))).getSeconds())}s</span></p></div>
                              <div style={{width:"5%", margin:"2%", textAlign:"center", paddingTop:"20px"}} className="d-flex flex-column">

                              <div style={{width:"5%", marginTop:"-30px", textAlign:"center"}}><span onClick={() => this.infoticket(row.idsupportinfo,row.objectsupportinfo,row.statussupportinfo)} style={{color:'#2A83E8',marginTop:"40px"}}><EditIcon sx={{ width: '40px', height:'40px' }}/></span></div>
                              
                              {parseInt(row.statussupportinfo)== 1 ?

                                <span style={{color:'#198754',marginTop:"30px"}} ><CheckIcon /></span>

                                :

                                <div className='loadertranscription' style={{marginTop:"10px", textAlign:"center",marginLeft:"-10px",marginTop:"30px"}} onClick={() => this.showresolvedticket(row.idsupportinfo,row.objectsupportinfo)}></div>

                              }

                            </div>
                          </div>

                          </Card.Body>
                          </Card>
                      
                      ))}

                      </div>

                      <div style={{height:"50px", marginBottom:"50px"}}>

                      </div>

                    </MobileView>
    
                    </div>
                 
                  </div>

                  }

                </div>
                  
          );
        }
    
}

export default Support;
